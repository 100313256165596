.login-wrapper {
  max-width: 45rem;
  padding: 2.4rem;
  border-radius: 1.6rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.16);
  margin: 0 auto;
  margin-top: 10%;
}

/* 
form {
  margin-top: 4rem;
} */

.login-logo {
  max-height: 10rem;
  margin: 0 auto;
  display: block;
}

.login-input-container {
  padding: 1.8rem;
}

.login-button {
  color: #fff;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: linear-gradient(
    270deg,
    rgba(244, 168, 103, 1) 28%,
    rgba(255, 107, 132, 1) 100%
  );
  border: none;
  width: 15rem;
  height: 4rem;
  border-radius: 0.8rem;
  font-size: 1.6rem;
  box-shadow: 0 0.1rem 0.1rem 0 rgba(60, 64, 67, 0.08),
    0 0.1rem 0.3rem 0.1rem rgba(60, 64, 67, 0.16);
}

.login-input {
  width: 100%;
  border: 0;
  padding: 0.7rem 0;
  font-size: 1.6rem;
  border-bottom: 0.1rem solid #ccc;
  background: transparent;
  outline: none;
}

.login-input ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0.2rem;
  background-color: rgba(255, 107, 132, 1);
  transition: 0.4s;
  padding: 0;
}

.login-input:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}

.login-error-message {
  display: block;
  text-align: center;
  color: red;
  font-size: 13px;
}

.col-3 {
  width: 100%;
  position: relative;
}
