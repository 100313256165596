@import './checkbox.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.topbar-icon {
  width: 40px !important;
  height: 40px;
  padding: 1px;
  margin-left: 2px;
  margin-right: 2px;
}

div.pulls-right>* {
  float: right;
}

.has-full-alpha {
  opacity: 1 !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* start admin */

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  height: auto;
  background: #ECF0F3;
  min-height: 100vh;
}

.ReactModal__Overlay {
  z-index: 100;
}

nav.navbar {
  border-top: 4px solid #276cda;
  margin-bottom: 1rem;
}

.container {
  margin-left: 20px !important;
  margin-right: 20px !important;
  max-width: 100% !important;
}

.navbar-item.brand-text {
  font-weight: 300;
}

.navbar-item,
.navbar-link {
  font-size: 14px;
  font-weight: 700;
}

.columns {
  width: 100%;
  height: 100%;
  margin-left: 0;
}

.menu-label {
  color: #8F99A3;
  letter-spacing: 1.3;
  font-weight: 700;
}

.menu-list a {
  color: #0F1D38;
  font-size: 14px;
  font-weight: 700;
}

.menu-list a:hover {
  background-color: transparent;
  color: #276cda;
}

.menu-list a.is-active {
  background-color: transparent;
  color: #276cda;
  font-weight: 700;
}

.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  margin-bottom: 2rem;
}

.card-header-title {
  color: #8F99A3;
  font-weight: 400;
}

.info-tiles {
  margin: .5rem 0;
}

.info-tiles .subtitle {
  font-weight: 300;
  color: #8F99A3;
}

.hero.welcome.is-info {
  background: #36D1DC;
  background: -webkit-linear-gradient(to right, #5B86E5, #36D1DC);
  background: linear-gradient(to right, #5B86E5, #36D1DC);
}

.hero.welcome .title,
.hero.welcome .subtitle {
  color: hsl(192, 17%, 99%);
}
.ordine-selected {
  background-color: rgba(54, 209, 220, 0.4) !important;
}
.card .content {
  font-size: 14px;
}
.topbar-button {
  margin-left:10px;
  margin-right:10px;
}

.card-footer-item {
  font-size: 14px;
  font-weight: 700;
  color: #8F99A3;
}

.card-footer-item:hover {}
.modal-section {
  overflow-y:scroll;
  max-height:700px;
}
.card-table .table {
  margin-bottom: 0;
}

.section-ordini {
  overflow-y: scroll;
  max-height: 750px;
}

.events-card .card-table {
  max-height: 250px;
  overflow-y: scroll;
}


/* end admin */


/* less space between boxes */

.box:not(:last-child),
.content:not(:last-child),
.notification:not(:last-child),
.progress:not(:last-child),
.table:not(:last-child),
.table-container:not(:last-child),
.title:not(:last-child),
.subtitle:not(:last-child),
.block:not(:last-child),
.highlight:not(:last-child),
.breadcrumb:not(:last-child),
.level:not(:last-child),
.list:not(:last-child),
.message:not(:last-child),
.pagination:not(:last-child),
.tabs:not(:last-child) {
  margin-bottom: 0.8rem !important;
}


/* Christian */

.box {
  box-shadow: 0 0.2em .3em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02)!important;
}

.is-clickable {
  cursor: pointer
}

.react-modal {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 1.5rem;
  border-radius: 15px;
}

.react-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .6)
}

.modal-r {
  /* max-width: 800px; */
  max-width: 1000px;
  /* min-height: 500px; */
  min-height: 700px;
  /* max-height: 900px; */
  max-height: 1200px;
}

.modal-r-small {
  max-width: 600px;
  min-height: 400px;
  max-height: 400px;
}

.modal-r-large {
  max-width: 1200px;
  min-height: 500px;
  max-height: 950px;
}
#regole table thead tr th:last-child {
  width: 120px;
}
#modificaButton.button{
  margin-right: 13px;  
}
​
.modal-r section {
  height: 530px;
  overflow-y:scroll;
}

.custom-box .row label,
.custom-box-child label {
  margin-left: 1.4rem;
  color: #7a7a7a !important
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.hr {
  margin: 1rem 0
}

.zoomIn {
  animation-duration: 0.5s;
  animation-name: zoomIn;
}

.date-row {
  margin-bottom: .5rem;
}

.date-row span {
  vertical-align: middle;
  display: inline-block;
  width: 30px;
}

.button-group-modal {
  /* position: absolute;
  top: 150px;
  right: 30px; */
  margin-top: 50px;
}