.custom-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #eee;
  transition: all .3s;
}

.custom-container:hover input~.custom-checkmark {
  background-color: #ccc;
}

.custom-container input:checked~.custom-checkmark {
  background-color: #4ea1e2;
}

.custom-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-container input:checked~.custom-checkmark:after {
  display: block;
}

.custom-container .custom-checkmark:after {
  left: 7px;
  top: 4px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}